@import url(~normalize.css/normalize.css);
/* Clearfix */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
.cf {
  *zoom: 1; }

* {
  box-sizing: border-box; }

body {
  font-family: "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  color: #696969;
  background: #ffffff; }

@media only screen and (max-width: 1024px) {
  .main-wrapper {
    margin-top: 85px; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.inside-page-nav {
  padding-top: 15px;
  margin-top: -15px; }
  @media only screen and (max-width: 1024px) {
    .inside-page-nav {
      padding-top: 100px;
      margin-top: -100px; } }

.container {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
  /**
    * For modern browsers
    * 1. The space content is one way to avoid an Opera bug when the
    *    contenteditable attribute is included anywhere else in the document.
    *    Otherwise it causes space to appear at the top and bottom of elements
    *    that are clearfixed.
    * 2. The use of `table` rather than `block` is only necessary if using
    *    `:before` to contain the top-margins of child elements.
    */
  /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
  *zoom: 1; }
  .container::before, .container::after {
    content: '';
    /* 1 */
    display: table;
    /* 2 */ }
  .container::after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px;
  /**
    * For modern browsers
    * 1. The space content is one way to avoid an Opera bug when the
    *    contenteditable attribute is included anywhere else in the document.
    *    Otherwise it causes space to appear at the top and bottom of elements
    *    that are clearfixed.
    * 2. The use of `table` rather than `block` is only necessary if using
    *    `:before` to contain the top-margins of child elements.
    */
  /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
  *zoom: 1; }
  .row::before, .row::after {
    content: '';
    /* 1 */
    display: table;
    /* 2 */ }
  .row::after {
    clear: both; }

[class*='col-'] {
  float: left;
  padding-left: 15px;
  padding-right: 15px; }

[class*='flex-col-'] {
  float: left;
  padding-left: 1.5%;
  padding-right: 1.5%; }

.col-1-6 {
  width: 165px; }

.flex-col-1-6 {
  width: 16.5%; }

.col-2-6 {
  width: 330px; }

.flex-col-2-6 {
  width: 33%; }

.col-3-6 {
  width: 495px; }

.flex-col-3-6 {
  width: 49.5%; }

.col-4-6 {
  width: 660px; }

.flex-col-4-6 {
  width: 66%; }

.col-5-6 {
  width: 825px; }

.flex-col-5-6 {
  width: 82.5%; }

.col-6-6 {
  width: 990px; }

.flex-col-6-6 {
  width: 99%; }

.btn-scroll {
  display: block;
  position: fixed;
  z-index: 999;
  height: 79px;
  width: 60px;
  cursor: pointer;
  background: #c1c6c9;
  opacity: 0.5;
  border-radius: 30px; }
  .btn-scroll:hover {
    background: #6c757d; }
  .btn-scroll.arrow-top {
    background-image: url("/img/arrow-scroll-top.png");
    bottom: 60px;
    left: 30px; }
  .btn-scroll.arrow-bottom {
    background-image: url("/img/arrow-scroll-bottom.png");
    top: 530px;
    left: 30px; }

.top-panel-wrapper {
  /**
    * For modern browsers
    * 1. The space content is one way to avoid an Opera bug when the
    *    contenteditable attribute is included anywhere else in the document.
    *    Otherwise it causes space to appear at the top and bottom of elements
    *    that are clearfixed.
    * 2. The use of `table` rather than `block` is only necessary if using
    *    `:before` to contain the top-margins of child elements.
    */
  /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
  *zoom: 1; }
  .top-panel-wrapper::before, .top-panel-wrapper::after {
    content: '';
    /* 1 */
    display: table;
    /* 2 */ }
  .top-panel-wrapper::after {
    clear: both; }
  @media only screen and (max-width: 1024px) {
    .top-panel-wrapper {
      position: fixed;
      top: 0;
      z-index: 1;
      background: #ecf0f3;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #cfcbcb; } }

.logo {
  float: left;
  padding-top: 25px;
  padding-left: 4.5%; }
  .logo .logo-image {
    width: 100%;
    height: auto; }
    @media only screen and (max-width: 350px) {
      .logo .logo-image {
        width: 90%; } }

.btn-menu {
  display: none; }

.nav-top {
  float: right;
  padding-top: 42px;
  padding-right: 4.5%; }
  .nav-top > ul {
    margin: 0;
    padding-left: 0;
    list-style: none; }
  .nav-top > ul > li {
    display: inline-block; }
  .nav-top > ul > li > a {
    display: block;
    width: 125px;
    margin-left: 15px;
    padding: 20px 0 30px 0;
    color: #696969;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none; }
    .nav-top > ul > li > a:hover {
      background: #0d1d28;
      color: #ffffff; }
    .nav-top > ul > li > a.active {
      color: #d44a3e; }

@media only screen and (min-width: 1025px) {
  .nav-top > ul > li:hover > a.sub-menu-link {
    background: #0d1d28 url("/img/chevron-down.png") no-repeat 50% 50px;
    color: #ffffff; } }

.nav-top > ul > li:hover > ul {
  display: block; }

li > ul {
  display: none;
  position: absolute;
  z-index: 200;
  margin: 0;
  padding-top: 15px;
  padding-left: 37px;
  list-style: none; }

li > ul.sub-menu-approach {
  padding-left: 35px; }

li > ul li a {
  display: block;
  padding: 8px;
  font-size: 0.875rem;
  font-weight: normal;
  color: #696969;
  text-decoration: none; }
  li > ul li a:hover {
    color: #ffffff; }

.banner-wrapper {
  position: relative;
  height: 400px;
  padding: 0;
  margin: 0;
  background: #000000; }

.sub-menu-bg {
  display: none;
  position: absolute;
  top: 0px;
  z-index: 100;
  height: 400px;
  width: 100%;
  background: url("/img/sub-menu-bg.png") repeat-x; }

@media only screen and (max-width: 1024px) {
  .logo {
    padding-top: 10px; }
  .btn-menu {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    padding: 6px 17px;
    margin-right: 12px;
    margin-top: 26px;
    width: 32px;
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    transition: all 250ms 10ms; }
    .btn-menu span {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 20px;
      margin-top: -1px;
      margin-left: -10px;
      background-color: #303030; }
      .btn-menu span::before, .btn-menu span::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #303030;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        transition: -webkit-transform 0.25s 0.1s;
        transition: transform 0.25s 0.1s;
        transition: transform 0.25s 0.1s, -webkit-transform 0.25s 0.1s; }
      .btn-menu span::before {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px); }
      .btn-menu span::after {
        -webkit-transform: translateY(8px);
                transform: translateY(8px); }
  .btn-menu.btn-menu-close {
    background-color: #303030;
    opacity: 0.95;
    right: 238px; } }
  @media only screen and (max-width: 1024px) and (max-width: 360px) {
    .btn-menu.btn-menu-close {
      right: 237px; } }

@media only screen and (max-width: 1024px) {
    .btn-menu.btn-menu-close span {
      background-color: transparent; }
      .btn-menu.btn-menu-close span::before {
        background: #ffffff;
        -webkit-transform: translateY(0) rotate(-45deg);
                transform: translateY(0) rotate(-45deg); }
      .btn-menu.btn-menu-close span::after {
        background: #ffffff;
        -webkit-transform: translateY(0) rotate(45deg);
                transform: translateY(0) rotate(45deg); }
  .nav-top {
    visibility: hidden;
    float: none;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    padding: 0;
    overflow: hidden;
    background: #303030;
    opacity: 0.95;
    transition: all 250ms; }
  .nav-top.nav-top-open {
    visibility: visible;
    width: 250px; }
  .nav-top > ul {
    height: 100%;
    padding-top: 22px; }
  .nav-top > ul > li {
    display: block; }
  .nav-top > ul > li > a {
    width: 100%;
    padding: 10px 0 10px 30px;
    margin-left: 0;
    color: #ffffff;
    text-align: left; }
    .nav-top > ul > li > a:hover {
      background: #444444; }
  li > ul {
    display: block;
    position: initial;
    margin: 0;
    padding: 0;
    list-style: none; }
  li > ul.sub-menu-approach {
    padding-left: 0; }
  li > ul li:hover {
    color: #ffffff;
    background: #444444; }
  li > ul li a {
    padding-left: 40px; }
  .banner-wrapper {
    display: none; } }

.banner {
  height: 400px;
  background: url("/img/banner-xl.png") no-repeat center center; }

.banner .text-1 {
  position: absolute;
  top: 103px;
  left: 401px;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase; }

.banner .text-2 {
  position: absolute;
  top: 121px;
  left: 460px;
  font-size: 60px;
  color: #ffffff;
  text-transform: uppercase; }

.nav-bottom {
  display: none; }
  @media only screen and (max-width: 870px) and (min-width: 705px) {
    .nav-bottom {
      display: block;
      padding: 20px 0;
      background: url("/img/footer-bg.png") repeat; } }
  .nav-bottom ul {
    padding: 0;
    margin: 0 auto;
    list-style: none; }
  .nav-bottom ul li {
    display: inline-block;
    vertical-align: top;
    margin-left: 100px; }
  .nav-bottom h1 {
    display: block;
    padding: 0 0 15px 0;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    color: #ffffff; }
  .nav-bottom a {
    display: block;
    padding: 0 0 5px 0;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    color: #8d8d8d;
    text-decoration: none; }
    .nav-bottom a:hover {
      color: #77bb52; }

.copyright-wrapper {
  background: #303030;
  min-height: 40px; }
  .copyright-wrapper .copyright-content {
    float: left;
    height: 25px;
    background: url("/img/logo-grey.png") no-repeat;
    padding: 4px 0 0 35px;
    margin: 8px 0 0 0;
    font-size: 0.875rem;
    font-weight: normal; }
    @media only screen and (max-width: 620px) {
      .copyright-wrapper .copyright-content {
        font-size: 0.5625rem; }
        .copyright-wrapper .copyright-content .copyright-date {
          display: block; } }
  .copyright-wrapper .btn-contact-us {
    float: right;
    display: block;
    width: 135px;
    padding: 11px 0px;
    margin: 0px;
    background: #1172b6;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .copyright-wrapper .btn-contact-us:hover {
      font-weight: 600; }
    @media only screen and (max-width: 380px) {
      .copyright-wrapper .btn-contact-us {
        display: none; } }

.services-wrapper, .approach-wrapper {
  padding-top: 50px;
  padding-bottom: 60px;
  text-align: center;
  color: #333333; }
  @media only screen and (max-width: 1024px) {
    .services-wrapper, .approach-wrapper {
      padding-top: 25px;
      padding-bottom: 30px; } }
  .services-wrapper h1, .approach-wrapper h1 {
    padding: 0 0 30px 0;
    margin: 0;
    font-size: 1.4375rem;
    font-weight: normal;
    text-transform: uppercase; }
    @media only screen and (max-width: 1024px) {
      .services-wrapper h1, .approach-wrapper h1 {
        padding-bottom: 15px; } }
  .services-wrapper p, .approach-wrapper p {
    padding: 0 0 47px 0;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.5; }
    @media only screen and (max-width: 1024px) {
      .services-wrapper p, .approach-wrapper p {
        padding-bottom: 30px; } }

.services-wrapper .service-list {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -60px; }
  @media only screen and (max-width: 1024px) {
    .services-wrapper .service-list {
      margin-bottom: -30px; } }

.services-wrapper .service-type {
  display: inline-block;
  width: 190px;
  margin-right: 28px;
  margin-left: 28px;
  margin-bottom: 60px;
  vertical-align: top;
  color: #333333;
  text-decoration: none; }
  @media only screen and (max-width: 1024px) and (min-width: 950px) {
    .services-wrapper .service-type {
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 950px) and (min-width: 870px) {
    .services-wrapper .service-type {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 870px) and (min-width: 705px) {
    .services-wrapper .service-type {
      margin-right: 60px;
      margin-left: 60px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 513px) {
    .services-wrapper .service-type {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 425px) {
    .services-wrapper .service-type {
      width: 120px;
      margin-right: 18px;
      margin-left: 18px;
      margin-bottom: 30px; } }
  .services-wrapper .service-type:hover {
    opacity: 0.6; }
  .services-wrapper .service-type h2 {
    padding: 25px 10px 0 10px;
    margin: 0;
    font-size: 1.4375rem;
    font-weight: 600;
    text-transform: uppercase; }
    @media only screen and (max-width: 1024px) {
      .services-wrapper .service-type h2 {
        padding-top: 15px; } }
    @media only screen and (max-width: 425px) {
      .services-wrapper .service-type h2 {
        font-size: 1rem;
        padding-left: 0;
        padding-right: 0; } }
    .services-wrapper .service-type h2.last {
      padding: 25px 0 0 0; }
      @media only screen and (max-width: 1024px) {
        .services-wrapper .service-type h2.last {
          padding-top: 15px; } }

.company-wrapper {
  background: #ecf0f3;
  padding: 30px 0 20px 0; }
  .company-wrapper h1 {
    text-transform: uppercase;
    padding: 0 0 30px 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 600; }
    @media only screen and (max-width: 1024px) {
      .company-wrapper h1 {
        padding-bottom: 15px; } }
  .company-wrapper p {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.6;
    text-align: justify; }

.approach-wrapper .approach-list {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -60px; }
  @media only screen and (max-width: 1024px) {
    .approach-wrapper .approach-list {
      margin-bottom: -30px; } }

.approach-wrapper .approach-type {
  display: inline-block;
  width: 300px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 60px;
  vertical-align: top;
  text-align: left; }
  @media only screen and (max-width: 1024px) {
    .approach-wrapper .approach-type {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 1024px) and (min-width: 950px) {
    .approach-wrapper .approach-type {
      width: 278px; } }
  @media only screen and (max-width: 950px) and (min-width: 870px) {
    .approach-wrapper .approach-type {
      width: 251px; } }
  @media only screen and (max-width: 870px) and (min-width: 705px) {
    .approach-wrapper .approach-type {
      width: 196px; } }
  @media only screen and (max-width: 705px) and (min-width: 620px) {
    .approach-wrapper .approach-type {
      width: 168px; } }
  @media only screen and (max-width: 620px) {
    .approach-wrapper .approach-type {
      width: 330px; } }
  .approach-wrapper .approach-type h2 {
    text-transform: uppercase;
    padding: 30px 0 0 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 600; }
    @media only screen and (max-width: 1024px) {
      .approach-wrapper .approach-type h2 {
        padding-top: 20px; } }
  .approach-wrapper .approach-type p {
    min-height: 200px;
    padding: 30px 0 25px 0;
    margin: 0;
    text-align: justify; }
    @media only screen and (max-width: 1024px) {
      .approach-wrapper .approach-type p {
        padding: 15px 0;
        min-height: 174px; } }
    @media only screen and (max-width: 950px) and (min-width: 870px) {
      .approach-wrapper .approach-type p {
        min-height: 195px; } }
    @media only screen and (max-width: 870px) {
      .approach-wrapper .approach-type p {
        min-height: 0; } }
  .approach-wrapper .approach-type a {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    color: #4483c1;
    text-decoration: none; }
    .approach-wrapper .approach-type a:hover {
      font-weight: 600; }

.services-page-wrapper {
  padding-top: 50px; }
  @media only screen and (max-width: 845px) {
    .services-page-wrapper {
      padding-top: 25px; } }

.service-type-wrapper {
  margin-bottom: 50px; }
  @media only screen and (max-width: 845px) {
    .service-type-wrapper {
      margin-bottom: 20px; } }
  .service-type-wrapper .service-type-header {
    position: relative; }
    .service-type-wrapper .service-type-header div {
      height: 133px;
      margin-left: 155px;
      border-bottom-left-radius: 35px;
      border-top-right-radius: 35px; }
      @media only screen and (max-width: 845px) {
        .service-type-wrapper .service-type-header div {
          height: 85px;
          margin-left: 0; } }
      .service-type-wrapper .service-type-header div h1 {
        position: absolute;
        top: 50px;
        left: 200px;
        padding: 0;
        margin: 0;
        font-size: 1.4375rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #333333; }
        @media only screen and (max-width: 845px) {
          .service-type-wrapper .service-type-header div h1 {
            top: 30px;
            left: 105px;
            font-size: 1.2rem; } }
    .service-type-wrapper .service-type-header .s1 {
      background: url("/img/it-consalting_bg.png") no-repeat 253px 0, url("/img/it-consalting_1px_bg.png") repeat; }
    .service-type-wrapper .service-type-header .s2 {
      background: url("/img/custom-applications_bg.png") no-repeat 253px 0, url("/img/custom-applications_1px_bg.png") repeat; }
      @media only screen and (max-width: 375px) {
        .service-type-wrapper .service-type-header .s2 h1 {
          top: 20px;
          padding-right: 18px; } }
    .service-type-wrapper .service-type-header .s3 {
      background: url("/img/web-solutions_bg.png") no-repeat 253px 0, url("/img/web-solutions_1px_bg.png") repeat; }
    .service-type-wrapper .service-type-header .s4 {
      background: url("/img/re-engineering_bg.png") no-repeat 253px 0, url("/img/re-engineering_1px_bg.png") repeat; }
    .service-type-wrapper .service-type-header img {
      position: absolute;
      top: 0;
      left: 0;
      height: 133px; }
      @media only screen and (max-width: 845px) {
        .service-type-wrapper .service-type-header img {
          height: 85px; } }
  .service-type-wrapper .service-type-content {
    padding: 30px 10px 0 10px; }
    @media only screen and (max-width: 845px) {
      .service-type-wrapper .service-type-content {
        padding-top: 15px; } }
    .service-type-wrapper .service-type-content p {
      padding: 0 0 10px 0;
      margin: 0;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.6;
      text-align: justify; }

.approach-page-wrapper {
  padding-top: 50px; }
  @media only screen and (max-width: 990px) {
    .approach-page-wrapper {
      padding-top: 25px; } }

.approach-type-wrapper {
  margin-bottom: 50px; }
  @media only screen and (max-width: 990px) {
    .approach-type-wrapper {
      margin-bottom: 25px; } }
  .approach-type-wrapper .approach-type-header {
    position: relative;
    height: 132px;
    border-bottom-left-radius: 35px;
    border-top-right-radius: 35px; }
    .approach-type-wrapper .approach-type-header h1 {
      position: absolute;
      top: 52px;
      left: 50px;
      padding: 0;
      margin: 0;
      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff; }
    @media only screen and (max-width: 990px) {
      .approach-type-wrapper .approach-type-header {
        height: 70px; }
        .approach-type-wrapper .approach-type-header h1 {
          top: 20px; } }
  @media only screen and (min-width: 990px) {
    .approach-type-wrapper .a1 {
      background: url("/img/fixed-price_l_bg.png") no-repeat 0 0, url("/img/fixed-price_r_bg.png") no-repeat 695px 0, url("/img/fixed-price_1px_bg.png") repeat; } }
  @media only screen and (max-width: 990px) {
    .approach-type-wrapper .a1 {
      background: url("/img/fixed-price_l_bg.png") no-repeat 0 center, url("/img/fixed-price_1px_bg.png") repeat; } }
  @media only screen and (min-width: 990px) {
    .approach-type-wrapper .a2 {
      background: url("/img/outstaffing_l_bg.png") no-repeat 0 0, url("/img/outstaffing_r_bg.png") no-repeat 695px 0, url("/img/outstaffing_1px_bg.png") repeat; } }
  @media only screen and (max-width: 990px) {
    .approach-type-wrapper .a2 {
      background: url("/img/outstaffing_l_bg.png") no-repeat 0 center, url("/img/outstaffing_1px_bg.png") repeat; } }
  @media only screen and (min-width: 990px) {
    .approach-type-wrapper .a3 {
      background: url("/img/outsourcing_l_bg.png") no-repeat 0 0, url("/img/outsourcing_r_bg.png") no-repeat 695px 0, url("/img/outsourcing_1px_bg.png") repeat; } }
  @media only screen and (max-width: 990px) {
    .approach-type-wrapper .a3 {
      background: url("/img/outsourcing_l_bg.png") no-repeat 0 center, url("/img/outsourcing_1px_bg.png") repeat; } }
  .approach-type-wrapper .approach-type-content {
    padding: 30px 10px 0 10px; }
    @media only screen and (max-width: 990px) {
      .approach-type-wrapper .approach-type-content {
        padding-top: 15px; } }
    .approach-type-wrapper .approach-type-content p {
      padding: 0 0 10px 0;
      margin: 0;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.6;
      text-align: justify; }
    .approach-type-wrapper .approach-type-content p:last-child {
      padding: 0; }

.contact-page-wrapper .phone-contact, .contact-page-wrapper .email-contact {
  display: inline-block;
  padding-bottom: 25px;
  vertical-align: top; }
  .contact-page-wrapper .phone-contact h2, .contact-page-wrapper .email-contact h2 {
    padding: 0 0 15px 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #333333; }
  .contact-page-wrapper .phone-contact p, .contact-page-wrapper .email-contact p {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.6;
    text-align: left; }
    @media only screen and (min-width: 390px) and (max-width: 455px) {
      .contact-page-wrapper .phone-contact p, .contact-page-wrapper .email-contact p {
        font-size: 0.625rem; } }
    @media only screen and (max-width: 390px) {
      .contact-page-wrapper .phone-contact p, .contact-page-wrapper .email-contact p {
        font-size: 0.5625rem; } }
    @media only screen and (max-width: 351px) {
      .contact-page-wrapper .phone-contact p, .contact-page-wrapper .email-contact p {
        font-size: 0.875rem; } }

.contact-page-wrapper .alert-loading,
.contact-page-wrapper .alert-success, .contact-page-wrapper .alert-error {
  display: none;
  position: relative;
  padding: 12px 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid transparent; }

.contact-page-wrapper {
  padding-top: 50px;
  padding-bottom: 25px; }
  @media only screen and (max-width: 1024px) {
    .contact-page-wrapper {
      padding-top: 25px; } }
  .contact-page-wrapper #honeypot {
    display: none; }
  .contact-page-wrapper h1 {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #333333; }
  @media only screen and (max-width: 705px) {
    .contact-page-wrapper .direct-contact {
      margin-top: 30px; } }
  .contact-page-wrapper .phone-contact {
    padding-right: 25px; }
    @media only screen and (max-width: 705px) {
      .contact-page-wrapper .phone-contact {
        padding-right: 70px; } }
    @media only screen and (max-width: 475px) {
      .contact-page-wrapper .phone-contact {
        padding-right: 35px; } }
    .contact-page-wrapper .phone-contact span {
      font-size: 0.875rem;
      letter-spacing: .025rem; }
  .contact-page-wrapper .email-contact {
    display: block;
    float: right;
    margin-right: -80px; }
    @media only screen and (max-width: 705px) {
      .contact-page-wrapper .email-contact {
        display: inline-block;
        float: none;
        margin-right: 0; } }
    .contact-page-wrapper .email-contact a {
      font-size: 0.875rem;
      color: #006bb6; }
  .contact-page-wrapper .alert-show {
    display: block; }
  .contact-page-wrapper .alert-loading,
  .contact-page-wrapper .alert-success {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff; }
  .contact-page-wrapper .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .contact-page-wrapper .form-group {
    margin-top: 30px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 705px) {
      .contact-page-wrapper .form-group {
        margin-top: 20px;
        margin-bottom: 0; } }
  .contact-page-wrapper .form-column, .contact-page-wrapper .form-column-right {
    float: left;
    width: 50%;
    padding-left: 1.5%;
    padding-right: 1.5%; }
    @media only screen and (max-width: 705px) {
      .contact-page-wrapper .form-column, .contact-page-wrapper .form-column-right {
        width: 100%; } }
  .contact-page-wrapper .form-column-right {
    float: right; }
  .contact-page-wrapper .form-control {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 25px;
    border: 1px solid #cccccc;
    font-size: 0.875rem;
    font-style: italic;
    color: #999999; }
  .contact-page-wrapper .btn {
    display: block;
    float: right;
    width: 175px;
    padding: 11px 0;
    margin: 0;
    border: 0;
    background: #1172b6;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .contact-page-wrapper .btn:not(:disabled) {
      cursor: pointer; }
    .contact-page-wrapper .btn:disabled {
      opacity: .65; }
    .contact-page-wrapper .btn:hover {
      font-weight: 600; }
